import React, { useEffect, useRef, useState } from "react";
import { APIProvider, InfoWindow, Map } from "@vis.gl/react-google-maps";
import { DevicesClusteredMarkers } from "./DevicesClusteredMarkers";
import { FeatureCollection, Feature, Point } from "geojson";
import { Device } from "../../../interface/cluster";

type DevicesMapProps = {
    geojson: FeatureCollection<Point>;
    selectedDevice: Device | undefined
};

const DevicesMap: React.FC<DevicesMapProps> = ({ geojson, selectedDevice }) => {

    const [infoWindowData, setInfoWindowData] = useState<{
        anchor: google.maps.marker.AdvancedMarkerElement;
        devices: Device[],
        isCluster: boolean
        // features: Feature<Point>[];
    } | null>(null);

    const [numClusters, setNumClusters] = useState(0);

    // Store the map instance
    const mapInstance = useRef<google.maps.Map | null>(null);

    const handleIdle = (event: { map: google.maps.Map }) => {
        if (!mapInstance.current) {
            mapInstance.current = event.map; // Capture the map instance
        }
    };


    // Focus on the selected device when it changes
    useEffect(() => {
        if (selectedDevice && mapInstance.current) {
            mapInstance.current.setCenter({
                lat: selectedDevice?.location?.latitude,
                lng: selectedDevice?.location?.longitude,
            });
            mapInstance.current.setZoom(15); // Adjust the zoom level as needed
        }
    }, [selectedDevice]);

    return (
        <APIProvider apiKey={process.env.REACT_APP_MAP_API_KEY}>
            <div className="h-full w-full">
                <Map
                    mapId="b5387d230c6cf22f"
                    defaultCenter={{ lat: 3, lng: 101 }}
                    defaultZoom={8}
                    gestureHandling="greedy"
                    disableDefaultUI
                    className="h-full w-full custom-marker-clustering-map"
                >
                    <DevicesClusteredMarkers
                        geojson={geojson}
                        setNumClusters={setNumClusters}
                        setInfoWindowData={(data) => {
                            const { anchor, features, isCluster }: any = data;
                            const devices = isCluster ? features : features[0]["devices"];
                            setInfoWindowData({ anchor, devices, isCluster });
                        }}
                    />
                    {infoWindowData && (
                        <InfoWindow
                            anchor={infoWindowData.anchor}
                            onCloseClick={() => setInfoWindowData(null)}
                        >
                            <div className="bg-white shadow-lg rounded-lg p-2 w-72">
                                <ul className="space-y-3">
                                    {infoWindowData?.devices?.map((device: any, index: number) => (
                                        <li key={index} className="border-b pb-2 last:border-none">
                                            <p className="text-sm font-medium text-gray-600">
                                                <span className="font-bold text-gray-800">Device Code:</span>{" "}
                                                {device.deviceCode}
                                            </p>
                                            <p className="text-sm font-medium text-gray-600">
                                                <span className="font-bold text-gray-800">Mac Address:</span>{" "}
                                                {device.macAddress || "N/A"}
                                            </p>
                                            <p className="text-sm font-medium text-gray-600">
                                                <span className="font-bold text-gray-800">Serial Number:</span>{" "}
                                                {device.serialNumber || "N/A"}
                                            </p>
                                            <p className="text-sm font-medium text-gray-600">
                                                <span className="font-bold text-gray-800">Status:</span>{" "}
                                                <span
                                                    className={`px-2 py-1 rounded text-xs font-semibold ${device.isOnline
                                                        ? "bg-green-100 text-green-700"
                                                        : "bg-red-100 text-red-700"
                                                        }`}
                                                >
                                                    {device.isOnline ? "Online" : "Offline"}
                                                </span>
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </InfoWindow>
                    )}
                </Map>
            </div>
        </APIProvider>
    );
};

export default DevicesMap;