import { format } from 'date-fns';
import { Device } from '../interface/cluster';
import { FeatureCollection, Point } from "geojson";

const getDateFromTimestamp = (seconds: number, nanoseconds: number) => {
  const milliseconds = seconds * 1000 + nanoseconds / 1_000_000;
  return new Date(milliseconds);
};

export const convertToUTC = (seconds: number, nanoseconds: number) => {
  const date = getDateFromTimestamp(seconds, nanoseconds);
  return date.toUTCString();
};

export const convertToLocal = (seconds: number, nanoseconds: number) => {
  const date = getDateFromTimestamp(seconds, nanoseconds);
  return format(date, "EEE, dd MMM yyyy HH:mm:ss");
};

type GroupedDevices = {
  [key: string]: {
    location: {
      latitude: number;
      longitude: number;
    };
    devices: Device[];
  };
};


export const groupDevicesByCoordinates = (
  devices: Device[]
): { [key: string]: { devices: any[] } } => {
  return devices.reduce((acc: { [key: string]: { devices: any[] } }, device: Device) => {
    const key = `${device.location.latitude},${device.location.longitude}`;

    // Create a feature-like data object for each device
    const data = {
      id: device._id,
      isOnline: device.isOnline,
      deviceCode: device.deviceCode,
      macAddress:device.macAddress,
      serialNumber:device.serialNumber,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [device.location.longitude, device.location.latitude],
      },
      properties: {
        deviceCode: device.deviceCode,
        modelNumber: device.modelNumber,
        isOnline: device.isOnline,
      },
    };

    // If the key doesn't exist, initialize it
    if (!acc[key]) {
      acc[key] = {
        devices: [],
      };
    }

    // Add the data object to the devices array
    acc[key].devices.push(data);

    return acc;
  }, {});
};


export const convertGroupedDevicesToGeoJSON = (
  devices: Device[], groupedDevices: any
): FeatureCollection<Point> => {

  const geojson: FeatureCollection<Point> = {
    type: "FeatureCollection",
    features: devices.map((device: Device) => ({
      id: device._id,
      isOnline: device.isOnline,
      deviceCode: device.deviceCode,
      macAddress:device.macAddress,
      serialNumber:device.serialNumber,
      devices: groupedDevices[`${device.location.latitude},${device.location.longitude}`].devices,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [device.location.longitude, device.location.latitude],
      },
      properties: {
        deviceCode: device.deviceCode,
        modelNumber: device.modelNumber,
        isOnline: device.isOnline,
      },
    })),
  };
  return geojson;
};

