//MRT Imports
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    lighten,
} from '@mui/material';

//Icons Imports
import { useEffect, useMemo, useState } from 'react';
import AddEditModal from './addEditModal';
import ViewLogo from './viewLogo';
import UploadLogo from './uploadLogo';
import { icons } from '../../../utils/icons';

export type Airline = {
    _id: string
    name: string;
    code: string;
    logos: [];
    isActive: string;
};

const AirlineTable = () => {
    const [data, setData] = useState<Airline[]>([]);
    const [isAirlineModalOpen, setIsAirlineModalOpen] = useState(false);
    const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
    const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false);
    const [uploadLogoId, setUploadLogoId] = useState<string>('')
    const [selectedAirline, setSelectedAirline] = useState<Airline | null>(null);
    const [selectedLogos, setSelectedLogos] = useState<Airline | null>(null);

    const fetchAllAirline = async () => {
        const url = 'https://flight-api.industronicsadvansystem.com/airlines/getAll'
        try {
            const res = await fetch(url)
            const airlines = await res.json()
            setData(airlines.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleOpenModal = (airline: Airline | null = null) => {
        setSelectedAirline(airline);
        setIsAirlineModalOpen(true);
    };

    const handleViewLogoModal = (airline: Airline | null = null) => {
        setSelectedLogos(airline);
        setIsLogoModalOpen(true);
    };

    const handleUploadLogoModal = (airline: string) => {
        setUploadLogoId(airline)
        setIsUploadLogoModalOpen(true);
    };

    const handleCloseLogoModal = () => {
        setSelectedLogos(null);
        setIsLogoModalOpen(false);
    };

    const handleDeleteAirline = async (airlineId: string) => {
        const confirmed = window.confirm('Are you sure you want to delete this airline?');

        if (confirmed) {
            const url = `https://flight-api.industronicsadvansystem.com/airlines/delete/${airlineId}`;

            try {
                const res = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!res.ok) {
                    console.error('Delete failed:', res.status, res.statusText);
                    const errorData = await res.json();
                    console.log('Error details:', errorData);
                } else {
                    const deletedAirline = await res.json();
                    fetchAllAirline();
                }
            } catch (error) {
                alert(`'Error while deleting:', ${error}`)
            }
        } else {
            console.log('Deletion cancelled');
        }
    }

    const handleCloseModal = () => {
        setSelectedAirline(null);
        setIsAirlineModalOpen(false);
        fetchAllAirline();
    };

    useEffect(() => {
        fetchAllAirline()
    }, [])

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorFn: (row) => row.name,
                id: 'name',
                header: 'Name',
                enableClickToCopy: true,
                size: 250,
                Cell: ({ row }) => {
                    return (
                        <div className='font-semibold'>
                            {row.original.name}
                        </div>
                    );
                },
            },
            {
                accessorKey: 'code',
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                header: 'Code',
                size: 300,
                Cell: ({ row }) => {
                    return (
                        <div className='font-medium'>
                            {row.original.code}
                        </div>
                    );
                }
            },
            {
                id: 'actions',
                header: 'Logo',
                size: 150,
                Cell: ({ row }) => (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleViewLogoModal(row.original)}
                    >
                        View
                    </Button>
                ),
            },
            {
                accessorKey: 'createdAt',
                header: 'Create Date',
                size: 350,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue<string>());
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    });
                },
            },
        ],
        [],
    );


    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnFilterModes: false,
        enableColumnOrdering: false,
        enableGrouping: false,
        enableColumnPinning: true,
        enableFacetedValues: false,
        enableRowActions: true,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
            columnPinning: {
                left: ['mrt-row-expand', 'mrt-row-select'],
                right: ['mrt-row-actions'],
            },
        },
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 20, 30],
            shape: 'rounded',
            variant: 'outlined',
        },
        renderRowActionMenuItems: ({ row, closeMenu }) => [
            <MenuItem
                key={0}
                onClick={() => {
                    handleOpenModal(row.original);
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <icons.PI.PiNotePencilBold size={18} />
                </ListItemIcon>
                Edit
            </MenuItem>,
            <MenuItem
                key={1}
                onClick={() => {
                    handleUploadLogoModal(row.original._id);
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <icons.PI.PiUploadSimpleBold size={18} />
                </ListItemIcon>
                Add Logo
            </MenuItem>,
            <MenuItem
                key={2}
                onClick={() => {
                    handleDeleteAirline(row.original._id)
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <icons.PI.PiTrashBold size={18} />
                </ListItemIcon>
                Delete
            </MenuItem>,
        ],
        renderTopToolbar: ({ table }) => {
            return (
                <div className='flex justify-end gap-x-2 p-2'>
                    <div className='flex gap-x-2'>
                        <MRT_GlobalFilterTextField table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                    </div>

                    <button type="button" onClick={() => handleOpenModal()} className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300">
                        <icons.PI.PiPlusBold className='mr-2' />
                        Add Airline
                    </button>
                </div>
            );
        },
    });

    return (
        <div>
            <MaterialReactTable table={table} />
            {isAirlineModalOpen && <AddEditModal onClose={handleCloseModal} airline={selectedAirline} />}
            {isLogoModalOpen && <ViewLogo onClose={handleCloseLogoModal} airlineId={selectedLogos?._id} />}
            {isUploadLogoModalOpen && <UploadLogo onClose={() => { setIsUploadLogoModalOpen(false) }} airlineId={uploadLogoId} />}
        </div>

    )
}

export default AirlineTable