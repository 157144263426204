import React, { useEffect, useCallback } from "react";
import Supercluster, { ClusterProperties } from "supercluster";
import { useSupercluster } from "./hooks/use-supercluster";
import { GeoJsonProperties } from "geojson";
import { DeviceClusterMarker } from "./DeviceClusterMarker";
import { DeviceMarker } from "./DeviceMarker";
import { Device } from "../../../interface/cluster";

type DevicesClusteredMarkersProps = {
  geojson: GeoJSON.FeatureCollection<GeoJSON.Point>;
  setNumClusters: (count: number) => void;
  setInfoWindowData: (
    data: {
      anchor: google.maps.marker.AdvancedMarkerElement;
      features: GeoJSON.Feature<GeoJSON.Point>[];
      isCluster?: boolean
    } | null
  ) => void;
};

const superclusterOptions: Supercluster.Options<
  GeoJsonProperties,
  ClusterProperties
> = {
  extent: 256,
  radius: 80,
  maxZoom: 12
};

export const DevicesClusteredMarkers: React.FC<DevicesClusteredMarkersProps> = ({
  geojson,
  setNumClusters,
  setInfoWindowData,
}) => {
  const { clusters, getLeaves } = useSupercluster(geojson, superclusterOptions);

  useEffect(() => {
    setNumClusters(clusters.length);
  }, [clusters.length, setNumClusters]);

  const handleClusterClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, clusterId: number) => {
      const leaves = getLeaves(clusterId);
      setInfoWindowData({ anchor: marker, features: leaves, isCluster: true });
    },
    [getLeaves, setInfoWindowData]
  );

  const handleMarkerClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, featureId: string) => {
      const feature: any = clusters.find(
        (cluster: any) => cluster.id === featureId
      ) as GeoJSON.Feature<GeoJSON.Point>;
      setInfoWindowData({ anchor: marker, features: [feature] });
    },
    [clusters, setInfoWindowData]
  );

  return (
    <>
      {clusters.map((feature: any) => {
        const [lng, lat] = feature.geometry.coordinates;
        const isCluster = feature.properties?.cluster;

        return isCluster ? (
          <DeviceClusterMarker
            key={feature.id}
            position={{ lat, lng }}
            size={feature.properties.point_count}
            clusterId={feature.properties.cluster_id}
            onMarkerClick={handleClusterClick}
          />
        ) : (
          <DeviceMarker
            deviceCount={feature?.devices?.length}
            deviceCode={feature ? feature.deviceCode : ""}
            isOnline={feature.isOnline}
            key={feature.id}
            position={{ lat, lng }}
            featureId={feature.id as string}
            onMarkerClick={handleMarkerClick}
          />
        );
      })}
    </>
  );
};