import ApexCharts from "apexcharts";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const OverViewAnalysis = () => {
    const [chartData, setChartData] = useState({ online: 0, offline: 0 });

    // Fetch API data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://dcmm-api.industronicsadvansystem.com/devices/getDeviceStatusCounts");
                const result = await response.json();
                if (result.success && result.data) {
                    setChartData(result.data);
                }
            } catch (error: any) {
                toast.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const getChartOptions = () => {
        return {
            series: [chartData.online, chartData.offline],
            colors: ["#1C64F2", "#16BDCA"],
            chart: {
                height: 420,
                width: "100%",
                type: "pie",
            },
            stroke: {
                colors: ["white"],
                lineCap: "",
            },
            plotOptions: {
                pie: {
                    labels: {
                        show: true,
                    },
                    size: "100%",
                    dataLabels: {
                        offset: -25,
                    },
                },
            },
            labels: ["Online", "Offline"],
            dataLabels: {
                enabled: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                },
            },
            legend: {
                position: "bottom",
                fontFamily: "Inter, sans-serif",
                formatter: (seriesName: string, opts: any) => {
                    const count =
                        seriesName === "Online" ? chartData.online : chartData.offline;
                    return `${seriesName}: ${count}`;
                },
            },
        };
    };

    useEffect(() => {
        const chart = new ApexCharts(document.getElementById("pie-chart"), getChartOptions());
        chart.render();
        return () => {
            chart.destroy();
        };
    }, [chartData]);

    return (
        <>
            <div className="w-full h-full bg-white rounded-lg shadow dark:bg-gray-800 p-4 md:p-6">
                <div className="flex justify-between items-start w-full border-b mb-4 mt-4 py-4">
                    <div className="flex-col items-center">
                        <div className="flex items-center mb-1">
                            <h5 className="text-xl font-bold leading-none text-gray-500 dark:text-white me-1">Analysis</h5>
                        </div>
                        <div className="flex items-center mb-1">
                            <h6 className="text-xl font-bold leading-none text-gray-900 dark:text-white me-1">Device Status</h6>
                        </div>
                    </div>
                    <div className="flex-col items-center">
                        <div className="flex items-center mb-1">
                            <h5 className="text-xl font-bold leading-none text-gray-500 dark:text-white me-1">Total Devices</h5>
                        </div>
                        <div className="text-right">
                            <h6 className="text-xl font-bold leading-none text-gray-900 dark:text-white me-1">{chartData.online + chartData.offline}</h6>
                        </div>
                    </div>
                </div>

                <div className="py-6" id="pie-chart"></div>
            </div>
        </>
    );
};

export default OverViewAnalysis;
