import FlightInfoDisplayLayout from "../components/overview/layout";

const FlightInfoDisplay = () => {
    return (
        <div className="relative h-full flex flex-col">
            <div className="flex flex-col h-full">
                <div className="bg-white font-bold text-xl ml-2">
                    FIDS Overview
                </div>
                <div className="p-2 flex-grow">
                    <FlightInfoDisplayLayout />
                </div>
            </div>
        </div>
    );
};

export default FlightInfoDisplay;

