import React from 'react';

/**
 * @copyright Castle by Rikas Dzihab from Noun Project (CC BY 3.0)
 * @link https://thenounproject.com/icon/castle-6703640/
 */
export const CastleSvg = () => (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="40"
  height="40"
  viewBox="0 0 8.4666669 8.4666669"
>
  <g transform="translate(0,-288.53332)">
    <path
      d="M 2,3 C 1.4477381,3.0000552 1.0000552,3.4477381 1,4 v 20 c 5.52e-5,0.552262 0.4477381,0.999945 1,1 h 28 c 0.552262,-5.5e-5 0.999945,-0.447738 1,-1 V 4 C 30.999945,3.4477381 30.552262,3.0000552 30,3 Z m 14,18 c 0.552285,0 1,0.447715 1,1 0,0.552285 -0.447715,1 -1,1 -0.552285,0 -1,-0.447715 -1,-1 0,-0.552285 0.447715,-1 1,-1 z m -1,5 v 1.001953 h -3.099609 c -1.334635,0.06901 -1.23112,2.070963 0.103515,2.001953 h 7.994141 c 1.334635,0 1.334635,-2.001953 0,-2.001953 H 17 V 26 Z"
      fill="black" // Apply the dynamic color
      transform="matrix(0.26458333,0,0,0.26458333,0,288.53332)"
    />
  </g>
</svg>
);