import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { icons } from "../../../../utils/icons";

interface DeviceCategoryNewEditProp {
  onClose: () => void;
  id?: string | null;
  successSave: () => void;
}

const DeviceCategoryNewEdit: React.FC<DeviceCategoryNewEditProp> = ({ onClose, id, successSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  // Fetch data if `id` is provided (Edit mode)
  useEffect(() => {
    if (id) {
      fetchCategoryById();
    }
  }, [id]);

  const fetchCategoryById = async () => {
    setFetching(true);
    try {
      const response = await fetch(
        `https://dcmm-api.industronicsadvansystem.com/device-categories/getById/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch category");
      }
      const result = await response.json();
      setFormData({
        name: result.data.name || "",
        description: result.data.description || "",
      });
    } catch (error) {
      alert("Failed to fetch category data.");
    } finally {
      setFetching(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setLoading(true);

    const url = id
      ? `https://dcmm-api.industronicsadvansystem.com/device-categories/update/${id}`
      : `https://dcmm-api.industronicsadvansystem.com/device-categories/create`;

    const method = id ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        toast.success(id ? "Category updated successfully!" : "Category created successfully!")
        setTimeout(() => {
          successSave();
        }, 1000)
      } else {
        toast.error(`Error: ${result.message || "Failed to save the data."}`)
      }
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save the data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Modal */}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* Header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {id ? "Edit Device Category" : "New Device Category"}
              </h3>
              <button
                type="button"
                onClick={onClose}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <span className="sr-only">Close modal</span>
                <icons.PI.PiX size={20} />
              </button>
            </div>
            {/* Body */}
            <div className="p-4 md:p-5 space-y-4">
              {fetching ? (
                <p>Loading category data...</p>
              ) : (
                <form className="w-full">
                  {/* Name Field */}
                  <div className="flex items-center mb-5 space-x-4">
                    <div className="flex justify-between min-w-32">
                      <div>
                        <label
                          htmlFor="description"
                          className="min-w-32 text-md font-bold text-gray-700 dark:text-gray-300"
                        >
                          Name
                        </label>
                      </div>
                      <div><p>:</p></div>
                    </div>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      placeholder="Enter name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  </div>

                  {/* Description Field */}
                  <div className="flex items-start space-x-4">
                    <div className="flex justify-between min-w-32">
                      <div>
                        <label
                          htmlFor="description"
                          className="text-md font-bold text-gray-700 dark:text-gray-300"
                        >
                          Description
                        </label>
                      </div>
                      <div><p>:</p></div>
                    </div>
                    <textarea
                      name="description"
                      id="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      placeholder="Enter description"
                      className="bg-gray-50 border min-h-24 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />


                  </div>
                </form>
              )}
            </div>
            {/* Footer */}
            <div className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
              <button
                onClick={handleSave}
                disabled={loading || fetching}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceCategoryNewEdit;
