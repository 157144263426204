import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';

import Header from '../../components/header/view/header';
import Sidebar from '../../components/navbar/sidebar';

import ClustersManager from '../flightInfoDisplay/view/clustersManager';
import FlightInfoDisplay from '../flightInfoDisplay/view/flightInfoDisplay';
import ActiveFlight from '../flightInfoManagement/view/activeFlight';
import Dashboard from '../dashboard/view/dashboard';
import DeviceManager from '../flightInfoDisplay/view/deviceManager';
import ResourceManagement from '../resourcesManagement/view/resourceManagement';
import AirlineList from '../flightInfoManagement/view/airlineList';
import DeviceOverview from '../flightInfoDisplay/components/deviceOverview';
import DeviceCategoryLayout from '../flightInfoDisplay/view/deviceCategoryLayout';
import TemplateEditor from '../flightInfoDisplay/view/templateEditor';

const MainLayout = () => {

    const Device = {
        bankId: 2,
        clientId: 'string',
        clusterId: 'string',
        deviceCode: 'string',
        ipAdress: 'string',
        name: 'string',
        sequence: 2,
        status: 1,
    }
    const [isOpen, setIsOpen] = useState(true);
    const isAuthenticated = true;

    const handleSidebarToggle = (isOpen: boolean | ((prevState: boolean) => boolean)) => {
        setIsOpen(isOpen);
    };

    return (
        <div className="flex h-screen w-screen overflow-hidden">
            <div className="flex flex-1 flex-col max-w-full">
                <Header onSidebarToggle={handleSidebarToggle} />
                <div className="flex flex-1 overflow-hidden transition-all duration-500 ease-in-out">
                    {/* Sidebar */}
                    <div
                        className={`bg-gray-800 text-white transition-all duration-500 ease-in-out ${isOpen ? 'w-64' : 'w-0'
                            } flex-shrink-0`}
                    >
                        <Sidebar />
                    </div>

                    <div
                        className={`flex-1 p-2 bg-slate-100 overflow-auto transition-all duration-500 ease-in-out}`}
                    >
                        <div className="bg-white p-4 rounded-lg shadow-lg max-w-full h-full overflow-auto">
                            <Routes>
                                <Route
                                    path="/dashboard"
                                    element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" replace />}
                                />

                                <Route path="/flight-info-management">
                                    <Route path="active-flight" element={<ActiveFlight />} />
                                    <Route path="airlines-list" element={<AirlineList />} />
                                </Route>

                                <Route path="/flight-info-display" element={<FlightInfoDisplay />} />

                                <Route path="/flight-info-display/device-manager" element={<DeviceManager />} />
                                
                                <Route path="/flight-info-display/template-editor" element={<TemplateEditor />} />

                                <Route path="/flight-info-display/device-category" element={<DeviceCategoryLayout />} />

                                <Route path="/flight-info-display/clusters" element={<ClustersManager />} />

                                <Route
                                    path="/flight-info-display/clusters/:id"
                                    element={<DeviceOverview onClose={() => { console.log('') }} />}
                                />

                                <Route path="/resource-management" element={<ResourceManagement />} />

                                <Route path="*" element={<Navigate to="/dashboard" />} />
                            </Routes>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
