import React, { useEffect, useState } from "react";
import Confirmation from "../../../components/shared/confirmationDialog";
import toast from "react-hot-toast";
import { labelOptions } from "../../../utils/clusterType";
import { icons } from "../../../utils/icons";

interface NewClusterModalProp {
    onClose: (shouldRefresh: boolean, isNew: boolean) => void;
    clusterId: string | null | undefined;
}

const NewClusterModal: React.FC<NewClusterModalProp> = ({ onClose, clusterId }) => {
    const initialFormState = {
        clusterCode: "",
        displayLabelCode: "",
        channel: "1",
        clientType: "1",
        location: "001",
    };

    const [newEditClusterForm, setNewEditClusterForm] = useState(initialFormState);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const fetchClusterById = async () => {
        try {
            const url = `https://dcmm-api.industronicsadvansystem.com/cluster/getById/${clusterId}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const resp = await response.json();
            if (resp.statusCode === 200 && resp.data) {
                const data = resp.data;
                setNewEditClusterForm({
                    clusterCode: data.clusterCode || "",
                    displayLabelCode: data.displayLabelCode || "",
                    channel: data.channel || "",
                    clientType: data.clientType || "",
                    location: data.location || "001",
                });
            } else {
                toast.error(resp.message || "Failed to fetch cluster data.");
            }
        } catch (error: any) {
            toast.error("Error fetching cluster data.", error);
        }
    };

    useEffect(() => {
        if (clusterId) {
            fetchClusterById();
        }
    }, [clusterId]);

    const validateForm = (form: typeof initialFormState) => {
        // Check if all fields are filled
        return Object.values(form).every((value) => value.trim() !== "");
    };

    const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewEditClusterForm((prev) => {
            const updatedForm = { ...prev, [name]: value };
            setIsFormValid(validateForm(updatedForm)); // Validate the form on every change
            return updatedForm;
        });
    };
    const onSave = async () => {
        // console.log('newEditClusterForm', newEditClusterForm)
        const url = clusterId
            ? `https://dcmm-api.industronicsadvansystem.com/cluster/update/${clusterId}`
            : "https://dcmm-api.industronicsadvansystem.com/cluster/create";

        const method = clusterId ? "PUT" : "POST";

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newEditClusterForm),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (clusterId) {
                toast.success("Cluster updated successfully!");
            } else {
                toast.custom(
                    (t) => (
                        <div
                            className={`${t.visible ? "animate-enter" : "animate-leave"
                                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                        >
                            <div className="flex-1 w-0 p-4 bg-green-200 rounded">
                                <div className="flex items-start">
                                    <div className="ml-3 flex-1">
                                        <p className="text-sm font-medium text-gray-900">
                                            Cluster created successfully!
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            Let's add device to your new cluster !
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex border-l border-gray-200">
                                <button
                                    onClick={() => {
                                        toast.dismiss(t.id);
                                        window.location.href = `/flight-info-display/clusters/${data.data._id}`;
                                    }}
                                    className="w-full border border-transparent p-4 flex items-center justify-center text-sm font-medium text-green-600 hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    <icons.PI.PiArrowFatLineRightBold size={22} />
                                </button>
                            </div>
                        </div>
                    ),
                    {
                        duration: 10000,
                    }
                );
            }

            onClose(true, clusterId ? false : true);
        } catch (error: any) {
            console.error("Error saving cluster:", error);
            toast.error(error.message || "Something went wrong");
        }
    };

    return (
        <div
            id="default-modal"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <div className="flex items-center">
                            <div
                                style={{
                                    backgroundImage: `url(${require("../../../assets/icon_1.png")})`,
                                    height: "40px",
                                    width: "40px",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                                className="mr-2"
                            ></div>
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {clusterId ? "Edit Cluster Detail" : "New Cluster Detail"}
                            </h3>
                        </div>
                        <button
                            onClick={() => onClose(false, false)}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex justify-center w-full">
                        <form>
                            <div className="p-4">
                                <div className="space-y-4">
                                    <div className="flex items-center space-x-4">
                                        <label
                                            htmlFor="clusterCode"
                                            className="w-48 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Cluster Code:
                                        </label>
                                        <input
                                            type="text"
                                            value={newEditClusterForm.clusterCode}
                                            name="clusterCode"
                                            id="clusterCode"
                                            onChange={handleFormChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5"
                                        />
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <label
                                            htmlFor="displayLabelCode"
                                            className="w-48 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Display Label Code:
                                        </label>
                                        <select
                                            id="displayLabelCode"
                                            name="displayLabelCode"
                                            value={newEditClusterForm.displayLabelCode}
                                            onChange={handleFormChange}
                                            className={`bg-gray-50 border border-gray-300 ${newEditClusterForm.displayLabelCode ? 'text-gray-900' : 'text-gray-400'}  text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full`}
                                        >
                                            <option value="" disabled>
                                                Select Display Label
                                            </option>
                                            {labelOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* <div className="flex items-center space-x-4">
                                        <label
                                            htmlFor="clientType"
                                            className="w-48 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Client Type:
                                        </label>
                                        <select
                                            id="clientType"
                                            name="clientType"
                                            value={newEditClusterForm.clientType}
                                            onChange={handleFormChange}
                                            className={`bg-gray-50 border border-gray-300 ${newEditClusterForm.clientType ? 'text-gray-900' : 'text-gray-400'}  text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full`}
                                        >
                                            <option value="" disabled>
                                                Select Type
                                            </option>
                                            <option value="Dedicated">Dedicated</option>
                                            <option value="Bank">Bank Monitor</option>
                                        </select>
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <label
                                            htmlFor="channel"
                                            className="w-48 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Channel:
                                        </label>
                                        <select
                                            id="channel"
                                            name="channel"
                                            value={newEditClusterForm.channel}
                                            onChange={handleFormChange}
                                            className={`bg-gray-50 border border-gray-300 ${newEditClusterForm.channel ? 'text-gray-900' : 'text-gray-400'}  text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full`}
                                        >
                                            <option value="" disabled>
                                                Select Channel
                                            </option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </select>
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="flex justify-end p-4 md:p-5 border-t border-gray-200 rounded-b">
                        <button
                            type="button"
                            onClick={onSave}
                            disabled={!isFormValid}
                            className={`px-4 py-2 text-white rounded ${isFormValid ? "bg-blue-500 hover:bg-blue-600" : "bg-gray-300 cursor-not-allowed"
                                }`}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default NewClusterModal;
