import { useState } from "react"
import { icons } from "../../../utils/icons"
import DeviceCategoryList from "../components/deviceCategory/dcList"
import DeviceCategoryNewEdit from "../components/deviceCategory/dcNewEditModal"

const DeviceCategoryLayout = () => {

    return (
        <div className="px-2">
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between">
                <div>
                    <h1 className="text-xl font-bold">Device Category</h1>
                </div>
            </div>
            <DeviceCategoryList />
        </div>

    )
}

export default DeviceCategoryLayout