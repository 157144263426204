import React, { useState } from "react";
import { icons } from "../../../utils/icons";
import toast from "react-hot-toast";

interface UploadLogoProps {
    onClose: () => void;
    airlineId: string;
}

const UploadLogo: React.FC<UploadLogoProps> = ({ onClose, airlineId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            setSelectedFile(file);

            // Generate a preview URL
            const reader = new FileReader();
            reader.onload = () => setPreviewUrl(reader.result as string);
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    };

    const handleSaveLogo = async () => {
        if (!selectedFile) {
            alert("No file selected!");
            return;
        }

        const logoUploadUrl = `https://flight-api.industronicsadvansystem.com/airlines/uploadLogo/${airlineId}`;
        const fileData = new FormData();
        fileData.append("logo", selectedFile);

        const uploadPromise = fetch(logoUploadUrl, {
            method: "PUT",
            body: fileData,
        }).then(async (resp) => {
            if (!resp.ok) {
                const errorData = await resp.json();
                throw new Error(errorData.message || "Upload failed");
            }
            await resp.json();
            setTimeout(() => {
                onClose();
            }, 1000);
        });

        toast.promise(
            uploadPromise,
            {
                loading: "Uploading the logo...",
                success: "Logo uploaded successfully!",
                error: (err) => `Error: ${err.message || "Failed to upload"}`,
            }
        );
    };

    return (
        <div
            id="static-modal"
            data-modal-backdrop="static"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Upload Logo</h3>
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="static-modal"
                        >
                            <icons.PI.PiX size={20} />
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 space-y-4">
                        <div className="flex flex-col items-center">
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full min-h-64 max-h-96 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        const file = e.dataTransfer.files[0];
                                        if (file) {
                                            handleFileChange({ target: { files: [file] } } as unknown as React.ChangeEvent<HTMLInputElement>);
                                        }
                                    }}
                                >
                                    {previewUrl ? (
                                        <div>
                                            <img
                                                src={previewUrl}
                                                alt="Logo Preview"
                                                className="max-h-96 object-contain border rounded-lg"
                                            />
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <icons.PI.PiUploadSimpleBold size={30} className="text-gray-500 mb-4" />
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">Click to upload</span> or drag and drop
                                            </p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG</p>
                                        </div>
                                    )}
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        className="hidden"
                                    />
                                </label>
                            </div>


                        </div>
                    </div>
                    <div className="flex justify-end items-center p-4 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button
                            onClick={handleSaveLogo}
                            type="button"
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Save
                        </button>
                        <button
                            onClick={onClose}
                            type="button"
                            className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadLogo;
