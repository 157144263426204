import { useEffect, useState } from "react";
import { icons } from "../../../utils/icons";
import NewDeviceModal from "../components/newDeviceModal";
import { DeviceData } from "../../../utils/deviceType";
import toast from "react-hot-toast";
import { BsFillCircleFill } from "react-icons/bs";
import { showCustomConfirmDialog } from "../../../components/shared/customConfirmDialog";

const DeviceManager = () => {
    const [data, setData] = useState<DeviceData[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredData, setFilteredData] = useState<DeviceData[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<string | undefined>();

    // PAGINATION
    const [currentPage, setCurrentPage] = useState<number>(1);
    const limit: number = 13;
    const [paginatedData, setPaginatedData] = useState<DeviceData[]>([]);

    const fetchDevices = async (): Promise<void> => {
        try {
            const url = `https://dcmm-api.industronicsadvansystem.com/devices/getAll`;
            const response = await fetch(url);

            if (response.ok) {
                const devices = await response.json();
                setData(devices.data);
                setFilteredData(devices.data);
            } else {
                const errorResponse = await response.json();
                toast.error(errorResponse.message || "Failed to fetch devices.");
            }
        } catch (error: any) {
            toast.error(error.message || "Failed to fetch devices.");
        }
    };


    const handleDeleteDevice = (deviceCode: string, deviceId?: string): void => {
        showCustomConfirmDialog({
            title: "Delete Device?",
            message: `Are you sure you want to delete this device "${deviceCode}"?`,
            onConfirm: async () => {
                const url = `https://dcmm-api.industronicsadvansystem.com/devices/delete/${deviceId}`;
                const deletePromise = fetch(url, {
                    method: "DELETE",
                }).then(async (resp) => {
                    if (!resp.ok) {
                        const errorData = await resp.json();
                        throw new Error(errorData.message || "Failed to delete the device.");
                    }
                    await resp.json();
                    fetchDevices();
                });

                toast.promise(deletePromise, {
                    loading: "Deleting the device...",
                    success: "Device deleted successfully!",
                    error: (err) => `Error: ${err.message || "Failed to delete."}`,
                });
            },
        });
    };

    useEffect(() => {
        const results = data.filter((device) =>
            [
                device.deviceCode,
                device.macAddress,
                device.serialNumber,
                device.modelNumber,
                device.brand,
                device.cluster?.clusterCode,
            ]
                .filter(Boolean) // Ensures null or undefined values are skipped
                .some((field) => field!.toLowerCase().includes(searchTerm.toLowerCase()))
        );
        setFilteredData(results);
    }, [searchTerm, data]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * limit;
        const endIndex = startIndex + limit;
        setPaginatedData(filteredData.slice(startIndex, endIndex));
    }, [currentPage, filteredData]);

    const handleCloseModal = (shouldRefresh: boolean): void => {
        if (shouldRefresh) {
            fetchDevices();
        }
        setIsModalOpen(false);
        setSelectedDevice('');
    };

    const editCluster = (device: string | undefined): void => {
        setSelectedDevice(device);
        setIsModalOpen(true);
    };

    useEffect(() => {
        fetchDevices();
    }, []);

    const formatDate = (dateString: string | undefined, isUTC: boolean): string => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
            timeZone: isUTC ? "UTC" : "Asia/Kuala_Lumpur",
        };

        // Use Intl.DateTimeFormat to extract parts
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const parts = formatter.formatToParts(date);

        // Extract individual parts
        const day = parts.find(part => part.type === "day")?.value;
        const month = parts.find(part => part.type === "month")?.value;
        const hour = parts.find(part => part.type === "hour")?.value;
        const minute = parts.find(part => part.type === "minute")?.value;

        if (!day || !month || !hour || !minute) {
            return ""; // Return empty string if any part is missing
        }

        // Combine parts into desired format
        return `${day} ${month}, ${hour}:${minute}`;
    };


    const totalDevices = filteredData.length;
    const totalPages = Math.ceil(totalDevices / limit);

    return (
        <div className="border-b border-gray-100 dark:border-gray-700 px-2">
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
                <div>
                    <h1 className="text-xl font-bold mb-4">Device Manager</h1>
                </div>
                <label htmlFor="table-search" className="sr-only">Search</label>
                <div className="flex space-x-2 items-center">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clipRule="evenodd"></path>
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search for devices"
                        />
                    </div>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="px-4 py-2 text-sm font-medium inline-flex items-center me-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-600"
                    >
                        <div className="w-3.5 h-3.5 me-2">
                            <icons.MD.MdAdd />
                        </div>
                        New Device
                    </button>
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Device Code
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Cluster
                            </th>
                            {/* <th scope="col" className="px-6 py-3">
                                Category
                            </th> */}
                            <th scope="col" className="px-6 py-3">
                                MAC Address
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brand
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Serial Number
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Attribute
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Last Active
                            </th>
                            <th scope="col" className="px-6 py-3 text-center">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length > 0 ? (
                            paginatedData.map((device, index) => (
                                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {device.deviceCode}
                                    </th>
                                    <td className="px-6 py-4">
                                        {device.cluster?.clusterCode}
                                    </td>
                                    {/* <td className="px-6 py-4">
                                        {device.category?.name}
                                    </td> */}
                                    <td className="px-6 py-4">
                                        {device.macAddress}
                                    </td>
                                    <td className="px-6 py-4">
                                        {device.brand}
                                    </td>
                                    <td className="px-6 py-4">
                                        {device.serialNumber}
                                    </td>
                                    <td className="px-6 py-4">
                                        <span>{device.monitorSize}</span>-<span className="font-bold">{device.orientation === 'L' ? 'L' : 'P'}</span> <span>{`(${device.resolution})`}</span>
                                    </td>
                                    <td className="px-6 py-4">
                                        {device.isOnline ?
                                            (<span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-green-400">
                                                Online
                                            </span>)
                                            :
                                            (
                                                <div className="flex gap-x-2 items-center">
                                                    <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400">
                                                        Offline
                                                    </span>
                                                </div>
                                            )}
                                    </td>
                                    <td className="px-6 py-4">
                                        {device.lastActiveAt ? formatDate(device.lastActiveAt, false) : ''}
                                    </td>
                                    <td className="px-6 py-4 flex justify-center gap-x-2 items-center align-middle">
                                        <span
                                            className="cursor-pointer mx-2 text-blue-500"
                                            onClick={() => editCluster(device._id)}
                                        >
                                            <icons.MD.MdEdit />
                                        </span>

                                        {/* Delete Icon */}
                                        <span
                                            className="cursor-pointer mx-2 text-red-500"
                                            onClick={() => handleDeleteDevice(device.deviceCode, device._id)}
                                        >
                                            <icons.MD.MdDelete />
                                        </span>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7} className="px-6 py-4 text-center text-gray-500 dark:text-gray-400">
                                    No devices found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between p-4" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                        Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * limit + 1}</span>-
                        <span className="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * limit, totalDevices)}</span> of
                        <span className="font-semibold text-gray-900 dark:text-white"> {totalDevices}</span>
                    </span>
                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                        <li>
                            <button
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === 1 ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                    } bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                            >
                                Previous
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => setCurrentPage(index + 1)}
                                    className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                        ? "text-white bg-blue-500"
                                        : "text-gray-500 hover:text-gray-700 bg-white"
                                        } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li>
                            <button
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === totalPages ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                    } bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                            >
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>

            </div>

            {isModalOpen && <NewDeviceModal onClose={handleCloseModal} deviceId={selectedDevice} />}
        </div>

    );
};

export default DeviceManager;
