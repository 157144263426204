import React, { useEffect, useState } from "react"
import { Airline } from "./airlineTable"
import { icons } from "../../../utils/icons"
import toast from "react-hot-toast";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CustomConfirmDialog, { showCustomConfirmDialog } from "../../../components/shared/customConfirmDialog";

interface ViewLogoProps {
    onClose: () => void
    airlineId: string | undefined;
}

interface Logo {
    dimension: {
        width: number,
        height: number,
    },
    _id: string,
    image: string
}

const ViewLogo: React.FC<ViewLogoProps> = ({ onClose, airlineId }) => {
    const gcloudToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg5Y2UzNTk4YzQ3M2FmMWJkYTRiZmY5NWU2Yzg3MzY0NTAyMDZmYmEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMjU1NTk0MDU1OS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMyNTU1OTQwNTU5LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAxNTUzNTM5NzgzOTEyNDI3ODc3IiwiaGQiOiJpbmR1c3Ryb25pY3NhZHZhbnN5c3RlbS5jb20iLCJlbWFpbCI6ImdkZXZAaW5kdXN0cm9uaWNzYWR2YW5zeXN0ZW0uY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJCLTNOV2J1UTZPNlY2Vkp0UkZjN29nIiwiaWF0IjoxNzM2Mzg3MzIyLCJleHAiOjE3MzYzOTA5MjJ9.tvkzJZCI_8vxq9ps-lce_yg0KtevwzsUuBaxzNlXdGGrmdbxS03fmgit1lT7Vq7_70bcjBcYsR_zFoAVXUCW3rm1v48ilFCbRdgN-ZRCT5flCOs-jHA2Kz9OBCGv8qFxzGV5liZbSjjv5evnlVmbhJzaE_wzlozZXkUq7p7aHasDYZYMcHmKh9H7pyMVEkzXKpQXs3z9ChZQSgPnOKvhrrWq3brjqgwlv33A6Zr8AXxJncKc9FJ531qKUx5BAq8wTp6j0g4foXTqk5RuwyrVCtQzSufVmlwVjZDEcZJPJMrhiBXxQlFyWOMwv41kEiKji4yEeQkubbrUfPlkV8W5Gg'
    const [airline, setAirline] = useState<Airline>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const fetchRMSGates = async () => {
        try {
            const url = 'https://rms-gas-580473669312.asia-southeast1.run.app/gates';

            if (!gcloudToken) {
                throw new Error('Authentication token is missing');
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${gcloudToken}`, // Pass the token in the Authorization header
                    Origin: 'http://localhost:3000/'
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch gates');
            }

            const data = await response.json();
            console.log('Fetched RMSGates:', data);

            return data; // Return data if needed
        } catch (error: any) {
            console.error('Error fetching RMSGates:', error.message);
            throw error; // Re-throw the error if needed for further handling
        }
    };


    const openModal = (image: string) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    const fetchAirlineById = async (airlineId: string | undefined) => {
        const url = `https://flight-api.industronicsadvansystem.com/airlines/getById/${airlineId}`;
        try {
            const resp = await fetch(url)
            if (!resp.ok) {
                const errorData = await resp.json();
                console.error('Fetch failed:', resp.status, resp.statusText, errorData);
            } else {
                const responseData = await resp.json();
                setAirline(responseData.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    useEffect(() => {
        fetchAirlineById(airlineId)
        // fetchRMSGates()

    }, [airlineId])

    const handleDelete = (logoId: string) => {
        showCustomConfirmDialog({
            title: 'Confirm to delete logo',
            message: 'Are you sure you want to delete this item?',
            onConfirm: async () => {
                const url = `https://flight-api.industronicsadvansystem.com/airlines/deleteLogo/${airlineId}/${logoId}`;
                const deletePromise = fetch(url, {
                    method: 'DELETE',
                }).then(async (resp) => {
                    if (!resp.ok) {
                        const errorData = await resp.json();
                        throw new Error(errorData.message || 'Failed to delete the logo');
                    }
                    await resp.json();
                    fetchAirlineById(airlineId);
                });

                toast.promise(
                    deletePromise,
                    {
                        loading: 'Deleting the logo...',
                        success: 'Logo deleted successfully!',
                        error: (err) => `Error: ${err.message || 'Failed to delete'}`,
                    }
                );
            }
        });
    };

    return (
        <div
            id="static-modal"
            data-modal-backdrop="static"
            tabIndex={-1}
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50">

            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {airline?.name} Logos
                        </h3>
                        <button
                            type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="static-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="p-2 md:p-2 space-y-2">
                        {airline?.logos.length && airline?.logos.length > 0 ? (
                            <div className="grid grid-cols-3 gap-3">
                                {airline?.logos.map((logo: Logo, index) => (
                                    <div
                                        key={index}
                                        className="relative max-w-xs bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                                    >
                                        <img
                                            className="rounded-t-lg w-full h-32 object-cover cursor-pointer"
                                            src={`https://storage.googleapis.com/pos-aviation-images-icons/${logo.image}`}
                                            alt=""
                                            onClick={() =>
                                                openModal(`https://storage.googleapis.com/pos-aviation-images-icons/${logo.image}`)
                                            }
                                        />

                                        <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-center py-1">
                                            <p className="text-xs">
                                                {`Dimensions: ${logo.dimension?.width || "Unknown"} x ${logo.dimension?.height || "Unknown"}`}
                                            </p>
                                        </div>

                                        <button
                                            className="absolute top-2 right-2 bg-white text-red-800 text-xs font-medium py-1 px-2 rounded shadow hover:bg-gray-100"
                                            onClick={() => handleDelete(logo._id)}
                                        >
                                            <icons.PI.PiTrashDuotone size={20} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center">
                                <div className="flex text-2xl my-10 text-gray-400 items-center justify-center text-center">
                                    <icons.PI.PiExclamationMarkDuotone />
                                    <p className="ml-2">No Logo Displayed</p>
                                </div>
                            </div>
                        )}

                        {/* Modal for enlarged image */}
                        {isModalOpen && selectedImage && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                                <div className="relative bg-white rounded-lg p-4 max-w-4xl">
                                    <img
                                        className="w-full h-auto rounded-lg"
                                        src={selectedImage}
                                        alt="Enlarged"
                                    />
                                    <button
                                        className="absolute top-2 right-2 text-white bg-black bg-opacity-50 p-2 rounded-full hover:bg-opacity-75"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button
                            onClick={onClose}
                            data-modal-hide="static-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewLogo