import React, { useEffect, useState } from "react";
import { icons } from "../../../utils/icons";
import { Cluster } from "../../../utils/clusterType";
import NewClusterModal from "./newClusterModal";
import { showCustomConfirmDialog } from "../../../components/shared/customConfirmDialog";
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';

interface ClusterListTableProp {
    onView: (isVisible: boolean, clusterId: string) => void;
    shouldRefesh: boolean
}

const ClusterListTable: React.FC<ClusterListTableProp> = ({ onView, shouldRefesh }) => {

    const navigate = useNavigate();

    const [clusterList, setClusterList] = useState<Cluster[]>([]);
    const [editCluster, setEditCluster] = useState<boolean>(false);
    const [selectedCluster, setSelectedCluster] = useState<string | null | undefined>(null);

    useEffect(() => {
        fetchData();
    }, [shouldRefesh]);

    const fetchData = async () => {
        try {
            const url = 'https://dcmm-api.industronicsadvansystem.com/cluster/getAll'
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const resp = await response.json();
            if (resp.statusCode === 200) {
                setClusterList(resp.data);
                console.log(resp.data)
            } else {
                toast.error(resp.message)
            }
        } catch (error: any) {
            toast.error(error)
        }
    }

    const handleModalClose = (shouldRefresh: boolean, isNew: boolean) => {
        setEditCluster(false)
        console.log(shouldRefresh, isNew)
        if (shouldRefresh === true) {
            fetchData()
        }
    }

    const handleEditCluster = (clusterId: string | null | undefined) => {
        setSelectedCluster(clusterId);
        setEditCluster(true);
    }

    const handleDeleteCluster = (clusterCode: string, clusterId?: string) => {
        showCustomConfirmDialog({
            title: 'Delete Cluster?',
            message: `Are you sure you want to delete this Cluster "${clusterCode}"`,
            onConfirm: async () => {
                const url = `https://dcmm-api.industronicsadvansystem.com/cluster/delete/${clusterId}`;
                const deletePromise = fetch(url, {
                    method: 'DELETE',
                }).then(async (resp) => {
                    if (!resp.ok) {
                        const errorData = await resp.json();
                        throw new Error(errorData.message || 'Failed to delete the cluster');
                    }
                    await resp.json();
                    fetchData()
                });

                toast.promise(deletePromise, {
                    loading: 'Deleting the cluster...',
                    success: 'Cluster deleted successfully!',
                    error: (err) => `Error: ${err.message || 'Failed to delete'}`,
                });
            },
        });
    };

    const handleNavigation = (clusterCode: any) => {
        console.log('clusterCode', clusterCode)
        navigate(`/flight-info-display/clusters/${clusterCode}`);
    };


    return (
        <div className="w-full">
            <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-md text-gray-900 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Cluster Code</th>
                        <th scope="col" className="px-6 py-3">Label Code</th>
                        {/* <th scope="col" className="px-6 py-3 text-center">Channel</th> */}
                        {/* <th scope="col" className="px-6 py-3">Type</th> */}
                        <th scope="col" className="px-6 py-3 text-center">Devices</th>
                        <th scope="col" className="px-6 py-3">Location</th>
                        <th scope="col" className="px-6 py-3 text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={10} className="border-t border-gray-400 my-4 py-2"></td>
                    </tr>
                    {clusterList.map((cluster, index) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td className="px-6 py-2 bg-white rounded-tl-2xl rounded-bl-2xl flex items-center">
                                    <icons.GO.GoDotFill className="mr-2" style={{ color: '#90ee90' }} />
                                    {cluster.clusterCode}
                                </td>
                                <td className="px-6 py-2 bg-white">{cluster.displayLabelCode}</td>
                                {/* <td className="px-6 py-2 bg-white text-center">{cluster.channel}</td> */}
                                {/* <td className="px-6 py-2 bg-white">{cluster.clientType}</td> */}
                                <td className="px-6 py-2 bg-white text-center">{cluster.devices.length}</td>
                                <td className="px-6 py-2 bg-white">{cluster.location}</td>
                                <td className="px-6 py-3 bg-white text-center flex justify-center gap-x-2 items-center align-middle">
                                    {/* Edit Icon */}
                                    <span
                                        className="cursor-pointer mx-2 text-blue-500"
                                        onClick={() => handleEditCluster(cluster._id)}
                                    >
                                        <icons.MD.MdEdit />
                                    </span>

                                    {/* Delete Icon */}
                                    <span
                                        className="cursor-pointer mx-2 text-red-500"
                                        onClick={() => handleDeleteCluster(cluster.clusterCode, cluster._id)}
                                    >
                                        <icons.MD.MdDelete />
                                    </span>
                                </td>
                                <td className="bg-white rounded-tr-2xl rounded-br-2xl">
                                    <icons.MD.MdKeyboardArrowRight
                                        size={25}
                                        onClick={() => handleNavigation(cluster._id)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </td>
                            </tr>
                            <tr className="h-2">
                                <td colSpan={4} className="bg-transparent"></td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>

            {editCluster && <NewClusterModal onClose={handleModalClose} clusterId={selectedCluster} />}
        </div>
    );
};

export default ClusterListTable;
