import AirlineTable from "../components/airlineTable"

const AirlineList = () => {
    return (
        <div className="dashboard-container px-2 flex flex-col h-full w-full">
            <h1 className="text-xl font-bold mb-2">Airline List</h1>
            <AirlineTable />
        </div>
    )
}

export default AirlineList