import React, { useEffect, useState } from "react";
import DeviceCategoryNewEdit from "./dcNewEditModal";
import { icons } from "../../../../utils/icons";
import toast from "react-hot-toast";
import { showCustomConfirmDialog } from "../../../../components/shared/customConfirmDialog";

interface DeviceCategory {
    _id: string;
    name: string;
    description: string;
}

const DeviceCategoryList: React.FC = () => {
    const [categories, setCategories] = useState<DeviceCategory[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<DeviceCategory[]>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState<string | null | undefined>(null)
    const [editCategory, setEditCategory] = useState<boolean>(false)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Fetch categories on component mount
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(
                "https://dcmm-api.industronicsadvansystem.com/device-categories/getAll"
            );

            if (!response.ok) {
                throw new Error("Failed to fetch categories");
            }

            const data = await response.json();
            setCategories(data.data || []);
            setFilteredCategories(data.data || []);
        } catch (err: any) {
            console.error("Error fetching categories:", err.message);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id: string) => {
        showCustomConfirmDialog({
            title: 'Delete Category?',
            message: "Are you sure you want to delete this Category",
            onConfirm: async () => {
                const url = `https://dcmm-api.industronicsadvansystem.com/device-categories/delete/${id}`;
                const deletePromise = fetch(url, {
                    method: 'DELETE',
                }).then(async (resp) => {
                    if (!resp.ok) {
                        const errorData = await resp.json();
                        throw new Error(errorData.message || 'Failed to delete the category');
                    }
                    await resp.json();
                    fetchCategories()
                });

                toast.promise(deletePromise, {
                    loading: 'Deleting the category...',
                    success: 'Category deleted successfully!',
                    error: (err) => `Error: ${err.message || 'Failed to delete'}`,
                });
            },
        });
    };

    const handleEdit = (id: string | null) => {
        setSelectedCategory(id)
        setEditCategory(true)
    };

    const successfullySaved = () => {
        fetchCategories()
        setEditCategory(false)
    }

    // Filter data when searchQuery changes
    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredCategories(categories);
        } else {
            const query = searchQuery.toLowerCase();
            const filtered = categories.filter(
                (item) =>
                    item.name.toLowerCase().includes(query) ||
                    item.description.toLowerCase().includes(query)
            );
            setFilteredCategories(filtered);
        }
    }, [searchQuery, categories]);

    return (
        <div className="container mx-auto">
            {loading && <p>Loading categories...</p>}
            {error && <p className="text-red-500">Error: {error}</p>}

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="pb-4 bg-white dark:bg-gray-900 flex justify-end items-center">
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="relative mt-1 flex items-center">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="table-search"
                            className="block pl-10 pr-4 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search for items"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />

                        <div className="flex items-center ml-2">
                            <button
                                onClick={() => { handleEdit(null) }}
                                className="px-4 py-2 text-sm font-medium inline-flex items-center me-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-600"
                            >
                                <div className="w-3.5 h-3.5 me-2">
                                    <icons.MD.MdAdd />
                                </div>
                                New Category
                            </button>
                        </div>
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Name</th>
                            <th scope="col" className="px-6 py-3">Description</th>
                            <th scope="col" className="px-6 py-3 text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCategories.map((category) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    {category.name}
                                </th>
                                <td className="px-6 py-4">{category.description}
                                </td>
                                <td className="px-6 py-3 text-center flex justify-center gap-x-2 items-center align-middle">
                                    {/* Edit Icon */}
                                    <span
                                        className="cursor-pointer mx-2 text-blue-500"
                                        onClick={() => handleEdit(category._id)}
                                    >
                                        <icons.MD.MdEdit />
                                    </span>

                                    {/* Delete Icon */}
                                    <span
                                        className="cursor-pointer mx-2 text-red-500"
                                        onClick={() => handleDelete(category._id)}
                                    >
                                        <icons.MD.MdDelete />
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {!loading && categories.length === 0 && (
                <p className="mt-4 text-center text-gray-500">No categories available.</p>
            )}

            {editCategory && <DeviceCategoryNewEdit successSave={successfullySaved} onClose={() => { setEditCategory(false) }} id={selectedCategory} />}
        </div>
    );
};

export default DeviceCategoryList;

