import React, { useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import {CastleSvg} from './castle-svg';


type DeviceClusterMarkerProps = {
  position: google.maps.LatLngLiteral;
  size: number;
  clusterId: number;
  onMarkerClick: (
    marker: google.maps.marker.AdvancedMarkerElement,
    clusterId: number
  ) => void;
};

export const DeviceClusterMarker: React.FC<DeviceClusterMarkerProps> = ({
  position,
  size,
  clusterId,
  onMarkerClick,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleClick = useCallback(
    () => onMarkerClick(marker!, clusterId),
    [onMarkerClick, marker, clusterId]
  );

  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
      className="marker cluster"
    >
    <CastleSvg />
    <span>{size}</span>
    </AdvancedMarker>
  );
};