import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { DeviceData } from "../../../../utils/deviceType";

const OfflineDeviceTable = () => {
    const [devices, setDevices] = useState<DeviceData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalDevices, setTotalDevices] = useState(0);
    const limit = 5;

    const fetchDevices = async (offset: number) => {
        try {
            const url = `https://dcmm-api.industronicsadvansystem.com/devices/getAll?limit=${limit}&offset=${offset}&status=offline`;
            const response = await fetch(url);
            const data = await response.json();

            if (response.ok) {
                setDevices(data.data || []);
            } else {
                throw new Error(data.message || "Failed to fetch devices");
            }
        } catch (error: any) {
            toast.error(error.message || "An error occurred while fetching devices");
        }
    };

    const formatDate = (dateString: string | undefined, isUTC: boolean) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
            timeZone: isUTC ? "UTC" : "Asia/Kuala_Lumpur",
        };
        return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const fetchAllOfllineDevices = async () => {
        try {
            const url = `https://dcmm-api.industronicsadvansystem.com/devices/getAll?status=offline`;
            const response = await fetch(url);
            const data = await response.json();

            if (response.ok) {
                setTotalDevices(data.data.length || 0); // Assuming the API response contains a "total" field
            } else {
                throw new Error(data.message || "Failed to fetch devices");
            }
        } catch (error: any) {
            toast.error(error.message || "An error occurred while fetching devices");
        }
    };

    useEffect(() => {
        const offset = (currentPage - 1) * limit;
        fetchDevices(offset);
        fetchAllOfllineDevices()
    }, [currentPage]);

    const totalPages = Math.ceil(totalDevices / limit);

    return (
        <div className="relative h-full overflow-x-auto shadow-md sm:rounded-lg">
            <p className="p-4 text-xl font-bold leading-none text-gray-500 dark:text-white me-1">Offline Devices</p>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">Device ID</th>
                        <th scope="col" className="px-6 py-3">Cluster ID</th>
                        <th scope="col" className="px-6 py-3">Device Type Code</th>
                        <th scope="col" className="px-6 py-3">Serial Number</th>
                        <th scope="col" className="px-6 py-3">Last Active</th>
                        <th scope="col" className="px-6 py-3">Status</th>
                        <th scope="col" className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((device, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {device.deviceCode}
                            </th>
                            <td className="px-6 py-4">{device.cluster?.clusterCode}</td>
                            <td className="px-6 py-4">{device.modelId}</td>
                            <td className="px-6 py-4">{device?.serialNumber}</td>
                            <td className="px-6 py-4">
                                {formatDate(device?.lastActiveAt, true)}
                            </td>
                            <td className="px-6 py-4">
                                <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">
                                    Offline
                                </span>
                            </td>
                            <td className="px-6 py-4 text-blue-600 cursor-pointer">View</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between p-4" aria-label="Table navigation">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                    Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * limit + 1}</span>-
                    <span className="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * limit, totalDevices)}</span> of
                    <span className="font-semibold text-gray-900 dark:text-white"> {totalDevices}</span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                    <li>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === 1 ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                } bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                        >
                            Previous
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index}>
                            <button
                                onClick={() => setCurrentPage(index + 1)}
                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                    ? "text-white bg-blue-500"
                                    : "text-gray-500 hover:text-gray-700 bg-white"
                                    } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === totalPages ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                } bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                        >
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default OfflineDeviceTable;
