import React, { useEffect, useMemo, useState } from "react";
import { DeviceData } from "../../../utils/deviceType";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

interface AddDeviceClusterProp {
    onClose: (shouldRefresh: boolean) => void;
}

const AddDeviceCluster: React.FC<AddDeviceClusterProp> = ({ onClose }) => {
    const [devices, setDevices] = useState<DeviceData[]>([]);
    const [filteredDevices, setFilteredDevices] = useState<DeviceData[]>([]);
    const [checkedDevices, setCheckedDevices] = useState<Record<string, boolean>>({});
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalDevices, setTotalDevices] = useState(0);
    const limit = 6;
    const { id } = useParams();

    // Fetch all devices
    const fetchDevices = async (offset: number) => {
        try {
            const response = await fetch(`https://dcmm-api.industronicsadvansystem.com/devices/getAll?limit=${limit}&offset=${offset}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();

            setDevices(data.data || []);
            setFilteredDevices(data.data || []);
        } catch (err: unknown) {
            if (err instanceof Error) {
                toast.error(err.message);
            } else {
                toast.error("An unexpected error occurred");
            }
        }
    };

    const fetchAllDevices = async () => {
        try {
            const response = await fetch(`https://dcmm-api.industronicsadvansystem.com/devices/getAll`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const data = await response.json();

            setTotalDevices(data.data.length || 0); // Assuming the API provides total count
        } catch (err: unknown) {
            if (err instanceof Error) {
                toast.error(err.message);
            } else {
                toast.error("An unexpected error occurred");
            }
        }
    };

    // Fetch cluster devices and set initial checked state
    const fetchClusterDevices = async () => {
        try {
            const response = await fetch(`https://dcmm-api.industronicsadvansystem.com/cluster/getById/${id}`);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const responsedata = await response.json();
            const deviceIds = responsedata.data.devices || [];

            const updatedCheckedDevices: Record<string, boolean> = {};
            deviceIds.forEach((deviceId: any) => {
                updatedCheckedDevices[deviceId._id] = true;
            });
            setCheckedDevices(updatedCheckedDevices);
        } catch (err: unknown) {
            if (err instanceof Error) {
                toast.error(err.message);
            } else {
                toast.error("An unexpected error occurred");
            }
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = devices.filter((device) =>
            device.deviceCode.toLowerCase().includes(query) ||
            device.cluster?.clusterCode?.toLowerCase().includes(query)
        );
        setFilteredDevices(filtered);
    };

    const handleCheckboxChange = (deviceId: string) => {
        setCheckedDevices((prev) => ({
            ...prev,
            [deviceId]: !prev[deviceId],
        }));
    };

    const onSave = async () => {
        const payload = {
            clusterId: id,
            deviceIds: Object.keys(checkedDevices).filter((deviceId) => checkedDevices[deviceId]),
        };

        try {
            const response = await fetch("https://dcmm-api.industronicsadvansystem.com/cluster/assignDevices", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            toast.success('Device successfully binded to this cluster !')

            onClose(true);
        } catch (error) {
            console.error("Error updating cluster:", error);
        }
    };

    useEffect(() => {
        const offset = (currentPage - 1) * limit;
        fetchDevices(offset);
        fetchClusterDevices();
        fetchAllDevices()
    }, [currentPage, id]);

    const totalPages = Math.ceil(totalDevices / limit);

    return (
        <div>
            <div
                id="static-modal"
                data-modal-backdrop="static"
                tabIndex={-1}
                aria-hidden="true"
                className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-6xl max-h-full">
                    <div className="relative bg-gray-100 rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-3 rounded-t dark:border-gray-600">
                            <div className="flex items-center">
                                <div
                                    style={{
                                        backgroundImage: `url(${require(`../../../assets/icon_1.png`)})`,
                                        height: '30px',
                                        width: '30px',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                    }}
                                    className="mr-2"
                                >
                                </div>

                                <h3 className="text-normal font-semibold text-gray-900 dark:text-white">
                                    Select Device
                                </h3>
                            </div>
                            <div className="w-96">
                                <input
                                    type="text"
                                    placeholder="Search devices..."
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                />
                            </div>
                            <div>
                                <button onClick={() => { onClose(false) }} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                        </div>
                        <div className="px-4">
                            <div className="grid grid-rows-[340px_1fr]">
                                <div className="">
                                    <div className="relative">
                                        {/* Table */}
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="p-4">
                                                        <div className="flex items-center">
                                                            <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">DEVICE CODE</th>
                                                    <th scope="col" className="px-6 py-3">Cluster Code</th>
                                                    <th scope="col" className="px-6 py-3">Device Type Code</th>
                                                    <th scope="col" className="px-6 py-3">Serial Number</th>
                                                    <th scope="col" className="px-6 py-3">Resolution</th>
                                                    <th scope="col" className="px-6 py-3">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredDevices.map((device, index) => (
                                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        <td className="w-4 p-4">
                                                            <div className="flex items-center">
                                                                <input
                                                                    id={device._id}
                                                                    type="checkbox"
                                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                                    checked={checkedDevices[device._id || ''] ?? false}
                                                                    onChange={() => handleCheckboxChange(device._id || '')}
                                                                />

                                                                <label htmlFor="checkbox-table-search-2" className="sr-only">checkbox</label>
                                                            </div>
                                                        </td>
                                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                            {device.deviceCode}
                                                        </th>
                                                        <td className="px-6 py-4">{device.cluster?.clusterCode}</td>
                                                        <td className="px-6 py-4">{device.modelId}</td>
                                                        <td className="px-6 py-4">{device?.serialNumber}</td>
                                                        <td className="px-6 py-4">{device.resolution}</td>
                                                        <td className="px-6 py-4">
                                                            <span className={`${device?.isOnline ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-green-400' : 'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-red-400'}`}>
                                                                {device?.isOnline ? 'Online' : 'Offline'}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between p-4" aria-label="Table navigation">
                                <div className="flex justify-between space-x-6 items-center">
                                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                                        Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * limit + 1}</span>-
                                        <span className="font-semibold text-gray-900 dark:text-white">{Math.min(currentPage * limit, totalDevices)}</span> of
                                        <span className="font-semibold text-gray-900 dark:text-white"> {totalDevices}</span>
                                    </span>
                                    <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                        <li>
                                            <button
                                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                disabled={currentPage === 1}
                                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === 1 ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                                    } bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                                            >
                                                Previous
                                            </button>
                                        </li>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li key={index}>
                                                <button
                                                    onClick={() => setCurrentPage(index + 1)}
                                                    className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1
                                                        ? "text-white bg-blue-500"
                                                        : "text-gray-500 hover:text-gray-700 bg-white"
                                                        } border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                                                >
                                                    {index + 1}
                                                </button>
                                            </li>
                                        ))}
                                        <li>
                                            <button
                                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                                disabled={currentPage === totalPages}
                                                className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === totalPages ? "text-gray-300" : "text-gray-500 hover:text-gray-700"
                                                    } bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                                            >
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </nav>

                            <div>
                                <button
                                    data-modal-hide="static-modal"
                                    type="button"
                                    onClick={onSave}
                                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={() => { onClose(false) }}
                                    data-modal-hide="static-modal"
                                    type="button"
                                    className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDeviceCluster;
