import React, { useEffect, useState } from "react";
import { monitorSize, resolutions, monitorBrands } from "../../../utils/sharedConst";
import toast from 'react-hot-toast';
import { icons } from "../../../utils/icons";
import { Category, DeviceData, Filters } from "../../../utils/deviceType";

interface NewDeviceModalProp {
    onClose: (shouldRefresh: boolean) => void;
    deviceId?: string | null
}

const NewDeviceModal: React.FC<NewDeviceModalProp> = ({ onClose, deviceId }) => {
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [templates, setTemplates] = useState<any[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null | undefined>(null);
    const [clusters, setClusters] = useState<any[]>([]);
    const [selectedCluster, setselectedCluster] = useState<string | null>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const defaultDeviceForm: DeviceData = {
        deviceCode: "",
        macAddress: "",
        serialNumber: "",
        modelNumber: "",
        modelId: "",
        templateId: "",
        brand: "",
        resolution: "",
        monitorSize: 0,
        orientation: "",
        cluster: null, // Initialize as null
        filters: {
            terminalId: [],
            tripType: '',
            serviceType: '',
            pagination: {
                limit: 10,
                offset: 0
            },
            flightType: ''
        },
    };

    const [deviceForm, setDeviceForm] = useState<DeviceData>(defaultDeviceForm);
    const [originalDeviceForm, setOriginalDeviceForm] = useState<DeviceData>(defaultDeviceForm);

    const handleUpdateCluster = (id: string) => {
        setselectedCluster(id); // Update the selectedCluster ID
        setDeviceForm((prev) => ({
            ...prev,
            cluster: {
                _id: id,
                clusterCode: prev.cluster?.clusterCode || "", // Ensure clusterCode remains a string
                displayLabelCode: prev.cluster?.displayLabelCode || "", // Ensure displayLabelCode remains a string
            },
        }));
    };


    const onSubmit = async () => {

        try {
            // Sanitize the payload
            const { cluster, isOnline, createdAt, updatedAt, lastActiveAt, category, location, _id, ...sanitizedForm } = deviceForm;

            const url = deviceId
                ? `https://dcmm-api.industronicsadvansystem.com/devices/update/${deviceId}`
                : `https://dcmm-api.industronicsadvansystem.com/devices/create`;

            const method = deviceId ? "PUT" : "POST";

            const payload = {
                ...sanitizedForm,
                cluster: selectedCluster, // Send the cluster ID as a string
            };

            const response = await fetch(url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const responseData = await response.json();
                toast.success(deviceId ? "Device updated successfully!" : "Device created successfully!");
                setTimeout(() => {
                    onClose(true);
                }, 1000);
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to save device.");
            }
        } catch (error) {
            toast.error("Failed to save device. Please try again.");
        }
    };

    const handleInputChange = (key: keyof DeviceData, value: string | number) => {
        setDeviceForm((prev) => ({ ...prev, [key]: value }));
        setIsDropdownOpen(false);
    };

    const handleNestedInputChange = (key: keyof Filters, value: string | number) => {
        setDeviceForm((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                [key]: value,
            } as Filters,
        }));
    };

    const handleLimitChange = (limit: number) => {
        setDeviceForm((prev) => ({
            ...prev,
            filters: {
                ...prev.filters,
                pagination: {
                    ...prev.filters?.pagination,
                    limit,
                    offset: 0, // Reset offset when limit changes
                },
            } as Filters,
        }));
    };

    const handlePageNumberChange = (pageNumber: number) => {
        setDeviceForm((prev) => {
            const limit = prev.filters?.pagination.limit || 10;
            return {
                ...prev,
                filters: {
                    ...prev.filters,
                    pagination: {
                        ...prev.filters?.pagination,
                        offset: (pageNumber - 1) * limit,
                    },
                } as Filters,
            };
        });
    };

    const fetchDeviceById = async () => {
        try {
            const url = `https://dcmm-api.industronicsadvansystem.com/devices/getById/${deviceId}`;
            const response = await fetch(url);
            const responseData = await response.json();
            setDeviceForm(responseData.data);
            setOriginalDeviceForm(responseData.data);
        } catch (error) {
            toast.error(`Error fetching device: ${error}`)
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(
                "https://dcmm-api.industronicsadvansystem.com/device-categories/getAll"
            );

            if (!response.ok) {
                throw new Error("Failed to fetch categories");
            }

            const data = await response.json();
            setCategories(data.data || []);
        } catch (err: any) {
            console.error("Error fetching categories:", err.message);
        } finally {

        }
    };

    const fetchTemplates = async () => {
        try {
            const response = await fetch(
                "https://editor-api.industronicsadvansystem.com/templates/getAll"
            );

            if (!response.ok) {
                throw new Error("Failed to fetch categories");
            }

            const data = await response.json();
            setTemplates(data.data || []);

        } catch (err: any) {
            console.error("Error fetching categories:", err.message);
        } finally {

        }
    };

    const fetchClusters = async () => {
        try {
            const response = await fetch(
                "https://dcmm-api.industronicsadvansystem.com/cluster/getAll"
            );

            if (!response.ok) {
                throw new Error("Failed to fetch cluster");
            }

            const responseData = await response.json();
            setClusters(responseData.data);
        } catch (err: any) {
            console.error("Error fetching cluster:", err.message);
        } finally {

        }
    };

    const validateForm = (form: any) => {
        return (
            form.deviceCode.trim() !== "" &&
            form.macAddress.trim() !== "" &&
            form.serialNumber.trim() !== "" &&
            form.modelNumber.trim() !== "" &&
            form.modelId.trim() !== "" &&
            form.brand.trim() !== "" &&
            form.resolution.trim() !== "" &&
            form.monitorSize > 0 &&
            form.orientation.trim() !== ""
        );
    };

    useEffect(() => {
        const isComplete = validateForm(deviceForm);
        const isChanged = JSON.stringify(deviceForm) !== JSON.stringify(originalDeviceForm);

        setIsFormComplete(isComplete && isChanged);
    }, [deviceForm, originalDeviceForm]);

    useEffect(() => {
        if (deviceId) {
            fetchDeviceById();
        } else {
            setDeviceForm(defaultDeviceForm);
            setOriginalDeviceForm(defaultDeviceForm);
        }
    }, [deviceId]);

    useEffect(() => {
        fetchCategories()
        fetchTemplates()
        fetchClusters()
    }, [])

    useEffect(() => {
        if (deviceForm) {
            const findElementContaining = (arr: string[], search: string): string | undefined => {
                return arr.find((item) => item.includes(search));
            };

            const result = findElementContaining(templates, deviceForm.templateId);
            setSelectedTemplate(result)
        }
    }, [])

    return (
        <>
            <div
                id="default-modal"
                tabIndex={-1}
                className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
            >
                <div className="relative w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center md:p-1 rounded-t dark:border-gray-600">
                            <h6 className="text-sm font-semibold text-gray-900 dark:text-white ml-2 pt-2">
                                {deviceId ? 'Edit Device' : 'New Device'}
                            </h6>

                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="default-modal"
                                onClick={() => onClose(false)}
                            >
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 14"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                    />
                                </svg>
                            </button>
                        </div>

                        <form>
                            <div className="grid pt-2 px-4 gap-4 grid-rows-[110px_170px_170px_140px]">

                                <div className="grid grid-cols-2 gap-2">
                                    <div className="border border-gray-400 rounded-lg shadow-lg">
                                        <div className="grid grid-rows-2 space-y-3 py-2">
                                            <div className="flex items-center gap-4 pt-1 px-4 h-7">
                                                <icons.PI.PiMonitor size={24} className="inline mt-4" />
                                                <div className="w-full">
                                                    <label htmlFor="small-input" className="block text-xs font-light text-gray-500">Device Code</label>
                                                    <input
                                                        type="text"
                                                        id="deviceCode"
                                                        value={deviceForm.deviceCode}
                                                        onChange={(e) => handleInputChange("deviceCode", e.target.value)}
                                                        placeholder="Device code" className="block w-full h-7 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4 px-4 h-7">
                                                <icons.PI.PiCirclesThreePlusLight size={24} className="inline mt-4" />
                                                <div className="w-full">
                                                    <label htmlFor="small-input" className="block text-xs font-light text-gray-500">Cluster</label>
                                                    <select
                                                        id="cluster"
                                                        value={deviceForm.cluster?._id || ""} // Use selectedCluster for value
                                                        onChange={(e) => handleUpdateCluster(e.target.value)}
                                                        className={`h-7 block w-full ${deviceForm.cluster?._id ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                    >
                                                        <option value="" disabled>Select Cluster</option>
                                                        {clusters.map((cluster) => (
                                                            <option key={cluster._id} value={cluster._id}>
                                                                {cluster.clusterCode}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="border border-gray-400 rounded-lg shadow-lg">
                                        <div className="grid grid-rows-2 space-y-3 py-2">
                                            <div className="flex items-center gap-4 pt-1 px-4 h-7">
                                                <icons.PI.PiWifiHigh size={24} className="inline mt-4" />
                                                <div className="w-full">
                                                    <label htmlFor="small-input" className="block text-xs font-light text-gray-500">MAC Address</label>
                                                    <input
                                                        type="text"
                                                        id="macAddress"
                                                        value={deviceForm.macAddress}
                                                        onChange={(e) => handleInputChange("macAddress", e.target.value)}
                                                        placeholder="00-B0-D0-63-C2-26" className="block w-full h-7 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4 px-4 h-7">
                                                <icons.PI.PiBarcodeLight size={24} className="inline mt-4" />
                                                <div className="w-full">
                                                    <label htmlFor="small-input" className="block text-xs font-light text-gray-500">Serial Number</label>
                                                    <input type="text" id="serialNumber" placeholder="Serial Number" className="block w-full h-7 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                        value={deviceForm.serialNumber}
                                                        onChange={(e) => handleInputChange("serialNumber", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Property</div>
                                    <div className="grid grid-rows-3 border border-gray-400 rounded-lg shadow-lg gap-2 py-2">
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.CI.CiHashtag size={20} className="inline" /> <span className="text-sm">Model Number</span></div>
                                            <div>
                                                <input type="text" id="modelNo" placeholder="Model Number" className=" h-7 block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    value={deviceForm.modelNumber}
                                                    onChange={(e) => handleInputChange("modelNumber", e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.TFI.TfiKey size={20} className="inline" /> <span className="text-sm">Model ID</span></div>
                                            <div>
                                                <input type="text" id="modelId" placeholder="Model ID" className=" h-7 block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                    value={deviceForm.modelId}
                                                    onChange={(e) => handleInputChange("modelId", e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiHexagonLight size={20} className="inline" /> <span className="text-sm">Brand</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="brand"
                                                    value={deviceForm.brand}
                                                    onChange={(e) => handleInputChange("brand", e.target.value)}
                                                    className={`h-7 block w-full ${deviceForm.brand ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value='' disabled>Select Brand</option>
                                                    {monitorBrands.map((brand) => (
                                                        <option key={brand} value={brand}>{brand}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiBookBookmarkLight size={20} className="inline" /> <span className="text-sm">Template</span>
                                            </div>

                                            <div className="w-40 relative">
                                                <button
                                                    type="button" // Ensures the button does not submit a form
                                                    className="h-7 w-full flex justify-between items-center border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 text-left px-2 focus:ring-blue-500 focus:border-blue-500 text-gray-600"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent any default behavior
                                                        setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
                                                    }}
                                                >
                                                    {selectedTemplate ? selectedTemplate : (<p className="text-gray-400">Select Template</p>)}
                                                    <icons.PI.PiCaretDownBold />
                                                </button>

                                                {isDropdownOpen && (
                                                    <div className="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full shadow-lg">
                                                        {templates.map((option, index) => (
                                                            <div
                                                                key={index}
                                                                className="flex justify-between items-center px-2 py-1 hover:bg-gray-100 cursor-pointer"
                                                                onClick={() => { handleInputChange("templateId", option._id); setSelectedTemplate(option.title) }}
                                                            >
                                                                <p className="text-xs text-gray-600">{option.title}</p>
                                                                <a
                                                                    href={`https://pa.industronicsadvansystem.com/template/${option.id}`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-500 text-xs underline"
                                                                >
                                                                    <icons.PI.PiArrowLineUpRightLight />
                                                                </a>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Filter</div>
                                    <div className="grid grid-rows-3 border border-gray-400 rounded-lg shadow-lg gap-2 py-2">
                                        {/* Trip Type */}
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiGlobeHemisphereEastLight size={20} className="inline" />{" "}
                                                <span className="text-sm">Trip Type</span>
                                            </div>
                                            <div className="w-40">
                                                <select
                                                    id="tripType"
                                                    value={deviceForm.filters?.tripType || ""}
                                                    onChange={(e) => handleNestedInputChange("tripType", e.target.value)}
                                                    className={`h-7 block w-full ${deviceForm.filters?.tripType ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value='' disabled>Select Trip Type</option>
                                                    <option value="Arrival">Arrival</option>
                                                    <option value="Departure">Departure</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* Pagination */}
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiSlidersHorizontal size={20} className="inline" />{" "}
                                                <span className="text-sm">Limit</span>
                                            </div>
                                            <div className="w-40">
                                                <select
                                                    id="limit"
                                                    value={deviceForm.filters?.pagination?.limit || 10}
                                                    onChange={(e) => handleLimitChange(parseInt(e.target.value))}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1"
                                                >
                                                    {Array.from({ length: 5 }, (_, index) => {
                                                        const value = (index + 1) * 10;
                                                        return <option key={value} value={value}>{value}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiBookOpenLight size={20} className="inline" />{" "}
                                                <span className="text-sm">Page Number</span>
                                            </div>

                                            <div className="w-40">
                                                {/* <Select
                                                    closeMenuOnSelect={false}
                                                    components={{ Option: CustomOption, ...animatedComponents }}
                                                    defaultValue={[colourOptions[0], colourOptions[1]]}
                                                    isMulti
                                                    options={colourOptions}
                                                /> */}
                                                <select
                                                    id="pageNumber"
                                                    value={
                                                        (deviceForm.filters?.pagination?.offset || 0) /
                                                        (deviceForm.filters?.pagination?.limit || 10) +
                                                        1
                                                    }
                                                    onChange={(e) => handlePageNumberChange(parseInt(e.target.value))}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1"
                                                >
                                                    {Array.from({ length: 10 }, (_, index) => {
                                                        const pageNumber = index + 1;
                                                        return <option key={pageNumber} value={pageNumber}>{pageNumber}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Terminal */}
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiAirTrafficControlLight size={20} className="inline" />{" "}
                                                <span className="text-sm">Terminal</span>
                                            </div>
                                            <div>
                                                {(() => {
                                                    const terminalIds = Array.isArray(deviceForm.filters?.terminalId)
                                                        ? deviceForm.filters.terminalId
                                                        : deviceForm.filters?.terminalId
                                                            ? [deviceForm.filters.terminalId]
                                                            : [];

                                                    return terminalIds.length > 0 ? (
                                                        terminalIds.map((terminal) => (
                                                            <span
                                                                key={terminal}
                                                                className="inline-flex items-center px-1 py-0.5 me-1 text-xs font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
                                                            >
                                                                {terminal}
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center p-0.5 ms-1 text-xs text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                                                                    onClick={() => {
                                                                        setDeviceForm((prev) => {
                                                                            const currentTerminals = Array.isArray(prev.filters?.terminalId)
                                                                                ? prev.filters.terminalId
                                                                                : prev.filters?.terminalId
                                                                                    ? [prev.filters.terminalId]
                                                                                    : [];

                                                                            return {
                                                                                ...prev,
                                                                                filters: {
                                                                                    ...prev.filters,
                                                                                    terminalId: currentTerminals.filter((id) => id !== terminal),
                                                                                },
                                                                            };
                                                                        });
                                                                    }}
                                                                    aria-label="Remove"
                                                                >
                                                                    <svg
                                                                        className="w-1.5 h-1.5"
                                                                        aria-hidden="true"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 14 14"
                                                                    >
                                                                        <path
                                                                            stroke="currentColor"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                                        />
                                                                    </svg>
                                                                    <span className="sr-only">Remove badge</span>
                                                                </button>
                                                            </span>

                                                        ))
                                                    ) : '';
                                                })()}
                                            </div>

                                            <div className="w-40">
                                                <select
                                                    id="terminal"
                                                    value=''
                                                    onChange={(e) => {
                                                        const selectedValue = e.target.value;
                                                        setDeviceForm((prev) => {
                                                            const currentTerminals = prev.filters?.terminalId || [];
                                                            if (!currentTerminals.includes(selectedValue)) {
                                                                return {
                                                                    ...prev,
                                                                    filters: {
                                                                        ...prev.filters,
                                                                        terminalId: [...currentTerminals, selectedValue],
                                                                    },
                                                                };
                                                            }
                                                            return prev; // Avoid duplicates
                                                        });
                                                    }}
                                                    className={`h-7 block w-full border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 ${deviceForm.filters?.terminalId?.length ? 'text-gray-600' : 'text-gray-400'
                                                        }`}
                                                >
                                                    <option value="" disabled>
                                                        Select Terminal
                                                    </option>
                                                    <option value="BGR">BGR</option>
                                                    <option value="CGO">CGO</option>
                                                    <option value="KL2">KL2</option>
                                                    <option value="MTB">MTB</option>
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-rows-[15px_1fr] gap-1">
                                    <div className="text-xs text-gray-600 px-1">Device Display Settings</div>
                                    <div className="grid grid-rows-3 border border-gray-400 rounded-lg shadow-lg gap-2 py-2">
                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiResize size={20} className="inline" /> <span className="text-sm">Resolution</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="resolution"
                                                    value={deviceForm.resolution}
                                                    onChange={(e) => handleInputChange("resolution", e.target.value)}
                                                    className={`h-7 block w-full ${deviceForm.resolution ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value='' disabled>Select Resolution</option>
                                                    {resolutions.map((res) => (
                                                        <option key={res} value={res}>{res} px</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div><icons.PI.PiArrowsOutSimple size={20} className="inline" /> <span className="text-sm">Monitor Size</span></div>
                                            <div className="w-40">
                                                <select
                                                    id="monitorSize"
                                                    value={deviceForm.monitorSize}
                                                    onChange={(e) => handleInputChange("monitorSize", e.target.value)}
                                                    className={`h-7 block w-full ${deviceForm.monitorSize ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value={0} disabled>Select Size</option>
                                                    {monitorSize.map((size, index) => (
                                                        <option key={size} value={size}>{size} inches</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiArrowsClockwise size={20} className="inline" /> <span className="text-sm">Orientation</span>
                                            </div>
                                            <div className="w-40">
                                                <select
                                                    id="orientation"
                                                    value={deviceForm.orientation}
                                                    onChange={(e) => handleInputChange("orientation", e.target.value)}
                                                    className={`h-7 block w-full ${deviceForm.orientation ? 'text-gray-600' : 'text-gray-400'} border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500`}
                                                >
                                                    <option value='' disabled>Select Orientation</option>
                                                    <option value={'L'}>Landscape</option>
                                                    <option value={'P'}>Portrait</option>
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className="flex items-center px-4 justify-between h-7">
                                            <div>
                                                <icons.PI.PiArrowsClockwise size={20} className="inline" /> <span className="text-sm">Category</span>
                                            </div>
                                            <div className="w-40">
                                                <select
                                                    id="category"
                                                    value={deviceForm.category || ""}
                                                    onChange={(e) => handleInputChange("category", e.target.value)}
                                                    className="h-7 block w-full text-gray-600 border border-gray-300 rounded-lg bg-gray-50 text-xs py-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                >
                                                    <option value="" disabled>Select Category</option>
                                                    {categories.map((category) => (
                                                        <option key={category._id} value={category._id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>

                        {/* <pre>{JSON.stringify(deviceForm, null, 2)}</pre> */}

                        <div className="flex justify-end px-4 py-3 md:p- dark:border-gray-600">
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 ${isFormComplete ? "" : "opacity-50 cursor-not-allowed"
                                    }`}
                                onClick={onSubmit}
                                disabled={!isFormComplete}
                            >
                                Save
                            </button>
                            <button
                                data-modal-hide="default-modal"
                                type="button"
                                className="py-1.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                onClick={() => onClose(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDeviceModal;
