export const resolutions = [
    "3840x2160",
    "2560x1440",
    "1920x1080",
    "1366x768",
    "1280x1024",
    "1440x900",
    "1600x900",
    "1680x1050",
    "1280x800",
    "1024x768"
]

export const monitorSize = [
    17,
    19,
    20,
    21.5,
    23,
    24,
    25,
    27,
    28,
    29,
    30,
    32,
    34,
    38,
    40,
    43,
    49
]

export const monitorBrands = [
    'Sony',
    'Samsung',
    'Philips',
    'Dell',
    'LG',
    'HP',
    'BenQ',
    'ViewSonic',
    'AOC',
    'ACER',
    'ASUS'
]

export const shortCutList = [{
    href: '/flight-info-display/template-editor',
    title: 'Template Editor',
    definition: 'Paging',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'Flight Tracker',
    definition: 'Overview',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'DCMM',
    definition: 'Device Manager List',
    background: 'templateEditor.png'
},
{
    href: '/flight-info-display/template-editor',
    title: 'DRE',
    definition: 'Display Rules Editor',
    background: 'templateEditor.png'
}
]

export const assignFlight = [
    {
        start: new Date(2024, 11, 6, 0, 5),
        end: new Date(2024, 11, 6, 0, 20),
        color: 'red',
        row: 1,
    },
    {
        start: new Date(2024, 11, 6, 1, 15),
        end: new Date(2024, 11, 6, 2, 45),
        color: 'blue',
        row: 1,
    },
    {
        start: new Date(2024, 11, 6, 0, 40),
        end: new Date(2024, 11, 6, 2, 30),
        color: 'green',
        row: 3,
    }
];

export const activeFlight = [
    {
        id: 1,
        flightNo: 'JA123',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 11, 0, 0),
        end: new Date(2024, 11, 11, 0, 20),
        color: 'Fuchsia',
        row: 1,
        type: 'inbound'
    },
    {
        id: 2,
        flightNo: 'JB345',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'In Flight',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 10, 0, 0),
        end: new Date(2024, 11, 10, 0, 20),
        color: 'Fuchsia',
        row: 1,
        type: 'inbound'
    },
    {
        id: 3,
        flightNo: 'JC567',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 10, 0, 20),
        end: new Date(2024, 11, 10, 1, 15),
        color: 'DarkSlateGray',
        row: 1,
        type: 'outbound'
    },
    {
        id: 4,
        flightNo: 'JD789',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 10, 0, 10),
        end: new Date(2024, 11, 10, 0, 45),
        color: 'GoldenRod',
        row: 2,
        type: 'inbound'
    },
    {
        id: 5,
        flightNo: 'JE1011',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 11, 0, 10),
        end: new Date(2024, 11, 11, 0, 45),
        color: 'GoldenRod',
        row: 2,
        type: 'inbound'
    },
    {
        id: 6,
        flightNo: 'JF1112',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 9, 0, 10),
        end: new Date(2024, 11, 9, 1, 45),
        color: 'GoldenRod',
        row: 2,
        type: 'outbound'
    },
    {
        id: 7,
        flightNo: 'JG1213',
        airline: 'JAPANESE AIRLINE (JAL)',
        aircraftReg: 'JL789A',
        status: 'On Time',
        OTE: 'ON TIME',
        STD: '001',
        STA: '001A',
        ETD: '002',
        ETA: '002A',
        ATD: '003',
        ATA: '003A',
        start: new Date(2024, 11, 12, 1, 10),
        end: new Date(2024, 11, 12, 2, 30),
        color: 'PaleVioletRed',
        row: 3,
        type: 'inbound'
    }
];

export const datas = ['Stand A1', 'Gate A1', 'Stand A2', 'Gate A2', 'Stand A3', 'Gate A3', 'Stand A4', 'Gate A4', 'Stand A5', 'Gate A5'];
export const flights = ['Button 1', 'Button 2', 'Button 3'];