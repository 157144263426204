import React, { useEffect, useState } from "react";
import { icons } from "../../../utils/icons";
import AddDeviceCluster from "./addDeviceCluster";
import { useNavigate, useParams } from 'react-router-dom';
import toast from "react-hot-toast";
import DevicesMap from "./DevicesMap";
import { ClusterData, Device } from "../../../interface/cluster";
import { convertGroupedDevicesToGeoJSON, groupDevicesByCoordinates } from "../../../utils/sharedFunction";
import { FeatureCollection, Point } from "geojson";

interface DeviceOverviewProp {
    onClose: () => void;
}

const DeviceOverview: React.FC<DeviceOverviewProp> = ({ onClose }) => {
    const navigate = useNavigate();

    const { id } = useParams();
    const [addDevice, setAddDevice] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<Device>();
    const [data, setData] = useState<any>();
    const [geojson, setGeojson] = useState<FeatureCollection<Point> | null>(null);

    const fetchDevices = async () => {
        try {
            const url = `${process.env.REACT_APP_DCMM_URL}/cluster/getById/${id}`
            const response = await fetch(url)
            const responseData = await response.json()
            if (response.ok) {
                setData(responseData.data as ClusterData);
                const devices = responseData.data.devices.filter((x: Device) => x.location && x.location.latitude && x.location.longitude);
                const groupedDevices = groupDevicesByCoordinates(devices);
                setGeojson(convertGroupedDevicesToGeoJSON(devices, groupedDevices));
                setData(responseData.data)
            }
        } catch (error: any) {
            toast.error(error)
        }
    }

    const handleOncloseModal = (shouldRefresh: boolean) => {
        if (shouldRefresh === true) {
            fetchDevices()
        }
        setAddDevice(false)
    }

    useEffect(() => {
        fetchDevices()
    }, [id]);


    return (
        <>
            <div className="h-full flex flex-col">
                <div className="flex-grow w-full bg-cover bg-center bg-no-repeat">
                    <div className="flex h-full space-x-4 shadow-lg">

                        {/* DEVICE LIST */}
                        <div className="w-96 h-full flex flex-col bg-white">

                            {/* Header Buttons */}
                            {/* <div className="h-8 flex items-center justify-end pt-2">
                                <button
                                    type="button"
                                    className="flex items-center cursor-pointer transition-colors duration-300 rounded-full rounded-3xl text-sm px-1 py-1 me-2 mb-2"
                                    onClick={() => { setIsActive(prev => !prev); }}
                                >
                                    {isActive ? (
                                        <icons.BS.BsToggleOn className="text-green-500" size={30} />
                                    ) : (
                                        <icons.BS.BsToggleOff className="text-gray-500" size={30} />
                                    )}
                                </button>

                                <button type="button" onClick={() => { setAddDevice(true); }} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                    <icons.MD.MdAddCircleOutline className="text-gray-500" />
                                </button>
                                <button type="button" onClick={onClose} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                    <icons.MD.MdFilterList className="text-gray-500" />
                                </button>
                                <button type="button" onClick={() => {
                                    navigate('/flight-info-display/clusters');
                                }} className="text-gray-900 bg-gray-100 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-100 font-medium rounded-3xl text-sm px-1 py-1 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                                    <icons.MD.MdClose className="text-gray-500" />
                                </button>
                            </div> */}

                            {/* Scrollable Device List */}
                            <div className="h-5 flex  items-center justify-between mb-2 font-medium bg-white">

                                <button onClick={() => { navigate('/flight-info-display/clusters'); }}>
                                    <icons.PI.PiArrowLeftBold className="mr-2" />
                                </button>

                                <div className="flex items-center">
                                    {/* <icons.GO.GoDotFill className="mr-2" style={{ color: '#90ee90' }} /> */}
                                    {data?.clusterCode}&nbsp;&nbsp;
                                    <small className="text-gray-400">{data?.devices?.length} (Devices)</small>
                                </div>
                            </div>



                            <div className="bg-slate-200 flex-grow overflow-y-auto">
                                <div className="px-3 rounded-lg pt-2">
                                    <div className="mb-2">

                                        <button
                                            type="button"
                                            onClick={() => {
                                                setAddDevice(true);
                                            }}
                                            className="px-3 py-2 text-xs w-full font-medium text-white bg-blue-700 rounded-lg inline-flex items-center justify-center space-x-1 hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300"
                                        >
                                            <icons.MD.MdAdd size={14} />
                                            <span>Add Device</span>
                                        </button>

                                    </div>
                                    {data?.devices?.length > 0 ? (
                                        Array.isArray(data.devices) && data.devices.map((device: any, index: number) => (
                                            <div key={index} className="mb-4">
                                                <button
                                                    className={`${selectedDevice?._id === device._id ? "border border-green-500" : ""
                                                        } bg-white rounded-lg p-3 shadow-md w-full h-20 flex items-center justify-center hover:shadow-2xl`}
                                                    onClick={() => {
                                                        if (device.location.latitude && device.location.longitude) setSelectedDevice(device);
                                                    }}
                                                >
                                                    <div className="grid grid-cols-6 w-full">
                                                        <div className="col-span-7">
                                                            <div className="grid grid-rows-2">
                                                                <div className="flex justify-between items-center">
                                                                    <div className="font-medium">{device.deviceCode}</div>
                                                                    <span
                                                                        className={`${device.isOnline
                                                                            ? "text-green-500 bg-white dark:bg-green-900 dark:text-green-300"
                                                                            : "text-red-500 bg-white dark:bg-red-900 dark:text-red-300"
                                                                            } border border-gray-200 text-xs font-medium px-2.5 py-0.5 rounded`}
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <icons.GO.GoDotFill
                                                                                className={device.isOnline ? "text-green-500" : "text-red-500"}
                                                                            />
                                                                            {device.isOnline ? "Online" : "Offline"}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div className="flex justify-between items-center">
                                                                    <p className="font-medium text-xs">
                                                                        Address :&nbsp;&nbsp;<span className="text-gray-400">{device.macAddress}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="flex justify-center items-center p-4 font-semibold text-gray-600">
                                            No Devices in This Cluster
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>

                        {/* SPECIFIC DEVICE CONFIG */}
                        {geojson && (
                            <DevicesMap selectedDevice={selectedDevice} geojson={geojson} />
                        )}

                    </div>
                </div>
            </div >

            {addDevice && <AddDeviceCluster onClose={handleOncloseModal} />
            }
        </>
    );
}

export default DeviceOverview;
