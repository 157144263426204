import { useEffect, useState } from "react";
import { icons } from "../../../utils/icons";
import { Airline } from "./airlineTable";

interface AddEditModalProps {
    onClose: () => void;
    airline: Airline | null;

}

const AddEditModal: React.FC<AddEditModalProps> = ({ onClose, airline }) => {
    // const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        code: '',
        isActive: 'true',
    });

    useEffect(() => {
        if (airline) {
            setFormData({
                id: airline._id,
                name: airline.name,
                code: airline.code,
                isActive: airline.isActive,
            });
        } else {
            setFormData({
                id: '',
                name: '',
                code: '',
                isActive: 'true',
            });
        }
    }, [airline]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSave = async () => {
        try {
            let result;
            const apiUrl = airline
                ? `https://pa.processor.industronicsadvansystem.com/airlines/update/${formData.id}`
                : `https://pa.processor.industronicsadvansystem.com/airlines/create`;

            // Create or update airline
            const response = await fetch(apiUrl, {
                method: airline ? 'PUT' : 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            result = await response.json();

        } catch (error) {
            console.error('Error saving airline:', error);
        } finally {
            onClose();
        }
    };

    // const getAirlineList

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            aria-hidden="true"
        >
            <div className="relative p-4 w-full max-w-3xl max-h-full">
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="relative p-4 w-full max-w-3xl max-h-full">
                        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                            <div className="flex items-center justify-between p-4 border-b">
                                <h3 className="text-xl font-semibold">
                                    {airline ? 'Edit Airline' : 'Add Airline'}
                                </h3>
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    data-modal-hide="static-modal"
                                >
                                    <icons.PI.PiX size={20} />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div className="p-4 space-y-4">
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Name"
                                    className="w-full p-2 border rounded"
                                />
                                <input
                                    type="text"
                                    name="code"
                                    value={formData.code}
                                    onChange={handleInputChange}
                                    placeholder="Code"
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                            <div className="flex justify-end p-4 border-t">
                                <button
                                    onClick={handleSave}
                                    className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
                                >
                                    Save
                                </button>
                                <button
                                    onClick={onClose}
                                    className="px-4 py-2 ml-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEditModal;
