export interface Cluster {
    _id: string;
    clusterCode: string;
    displayLabelCode: string;
    channel: string;
    clientType: string;
    filters: {
        terminalId: string;
        airport: string;
        flightType: string;
    };
    devices: []
    location: string;
    templateId: string;
}

export const labelOptions = [
    { value: "D00_General", label: "General" },
    { value: "D01_MUAIDS", label: "Multi User Arrival" },
    { value: "D02_MUBIDS", label: "Multi User Baggage Claim" },
    { value: "D03_BIDS", label: "Baggage Claim" },
    { value: "D04_MUCIDS", label: "Multi User Check In" },
    { value: "D05_MUGIDS", label: "Multi User Gate" },
    { value: "D06_CIDS", label: "Check In / Transfer Counter" },
    { value: "D07_GIDS", label: "Gate Counter" },
    { value: "D08_PAGING", label: "Ada Paging" },
    { value: "D09_EGIDS", label: "Boarding Gate Entry" },
    { value: "D10_MUCR", label: "Multi User Baggage Claim (Landed)" },
    { value: "D11_CheckInInfo", label: "Check In Information" },
    { value: "D12_Kerbside", label: "Kerbside Display" },
];